import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";
export default function Footer() {
  const navLinks = [
    { label: "About", url: "#about" ,section:'about'},
    { label: "Contact", url: "#contact" ,section:'contact'},
    { label: "Blog", url: "#" },
  ];
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-900">
      <div className="max-w-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center "
          aria-label="Footer"
        >
          <div className="flex md:block">
              <ul className="flex space-x-12 text-2xl">
                {navLinks.map((link) => (
                  <li key={link.label} className="flex">
                    <a
                      href={link.url}
                      className=" text-slate-100"
                    >
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; {currentYear} Usmael Abdurhaman. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
