// Import the functions you need from the SDKs you need
// import  initializeApp from "firebase/app";

import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyB2DkxG_m1mWN9TWYouM_gVNf0aWBR9ZAU",
    authDomain: "portfolio-website-f9505.firebaseapp.com",
    projectId: "portfolio-website-f9505",
    storageBucket: "portfolio-website-f9505.appspot.com",
    messagingSenderId: "1088526569252",
    appId: "1:1088526569252:web:82fba26e594ed93aab2db1"
  };
firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default firebase;