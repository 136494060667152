import { Helmet } from "react-helmet";
import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ClipLoader from "react-spinners/ClipLoader";

import tg from "../assets/img/telegram.svg";
import pn from "../assets/img/phone.svg";
import em from "../assets/img/email.svg";


const Card = () => {
  return (
    <div>
      <div className="max-w-xl mx-auto h-50 mb-1 bg-gradient-to-b from-teal-200 to-teal-500 top-4 rounded-lg overflow-hidden">
        <div className="flex justify-center bg-gray-800">
          <h1 className="mx-3 text-white font-semibold text-lg">Phone</h1>
        </div>
    
        <div className=" flex py-10 px-10  justify-center space-x-8 text-3lg">
            <a href="tel:+251935096554"> <img src={pn} width="20px"/></a>
          <span className="text-gray-700 flex">
            <a href="tel:+251935096554">+251935096554</a>
          </span>
        </div>
      </div>
      <div className="max-w-xl mx-auto h-50 bg-gradient-to-b from-teal-200 to-teal-500 top-4 shadow-md rounded-lg overflow-hidden">
        <div className="flex  items-center justify-center   px-4 bg-gray-800">
          <h1 className="mx-3 text-white font-semibold text-lg">Telegram</h1>
        </div>
        <div className=" flex py-10  justify-center space-x-10 text-3lg">
            <a href="https://t.me/kenbon4" target="_blank" rel="noopener noreferrer"
            >
             <img src={tg} width="20px"/>  </a>
          <span className="text-gray-700 flex">
            <a href="https://t.me/kenbon4" target="_blank">
            telegram
            </a>
          </span>
        </div>
      </div>
      <div className="max-w-xl mx-auto h-50 bg-gradient-to-b from-teal-200 to-teal-500 mt-4 shadow-md rounded-lg overflow-hidden">
        <div className="flex  justify-center px-4 bg-gray-800">
          <h1 className="mx-3 text-white font-semibold text-lg">Email</h1>
        </div>
        <div className=" flex py-10 px-4 justify-center space-x-8  text-2xl">
        
           
            <a href="mailto:uabdureman@gmail.com"> <img src={em} width="20px"/></a>
         
          <span className="text-gray-700 flex">
            <a href="mailto:uabdureman@gmail.com">uabdureman@gmail.com</a>
          </span>
        </div>
      </div>
    </div>
  );
};

const validate = (data) => {
  const errors = {};
  if (!data.firstname) {
    errors.firstname = "First Name is required";
  }
  if (!data.message) {
    errors.message = "Message is required";
  }

  if (!data.userEmail) {
    errors.userEmail = "Email is required";
  }
  return errors;
};

export default function Contact() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    userEmail: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [sending, setSending] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    const validationErrors = validate(formData);
    setSending(true);
    if (Object.keys(validationErrors).length === 0) {
      emailjs
        .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USER_ID)
        .then(
          (result) => {
            console.log(result.text);
            toast.success("Your message has been sent successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
          },
          (error) => {
            console.log(error.text);
            toast.error(
              "Sorry, there was an error sending your message. Please try again later.",
              { position: toast.POSITION.TOP_CENTER }
            );
          }
        )
        .finally(() => {
          setSending(false);
          // setErrors(!validationErrors)
          setFormData({
            firstname: "",
            lastname: "",
            userEmail: "",
            message: "",
          });
        });
    } else {
      setErrors(validationErrors);
    }
  };
  return (
    <div className="dark:bg-slate-900 dark:text-slate-200">
      <Helmet>
        <title>Usmael Abdurhaman Full-stack Web Developer</title>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="flex flex-col md:flex-row  px-4 sm:px-6 lg:max-w-7xl lg:px-20 " id="contact">
        <div className=" w-full md:w-1/2 p-4">
          <h2 className="text-3xl  text-teal-600 font-medium dark:text-teal-400  sm:text-4xl">
            Get In Touch with me!
          </h2>
          <p className="mt-4 text-lg">
            Whether you have any question or suggestion , or simply want to say
            hello, I'm here to listen. Please feel free to use the contact form
            below to get in touch with me, and I'll respond as soon as possible
            for me. Alternatively, you can reach out to me via email , telegram
            or phone using the contact information provided. Thank you!
          </p>
          <div className="my-4 justify-center items-center h-screen">
            <div>
              <Card />
            </div>
          </div>
        </div>

        <div className=" w-full md:w-1/2 p-4 mb-2">
          <form
            onSubmit={sendEmail}
            className="mt-9 grid grid-cols-1 shadow-md bg-slate-800 rounded px-8 pt-6 pb-8 mb-4 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <div className="mb-4">
              <label htmlFor="firstname" className="block text-sm  font-medium">
                First name
              </label>
              {/* <div className="mt-1"> */}
              <input
                type="text"
                name="firstname"
                placeholder="Enter your first Name"
                id="firstname"
                className="py-3 px-4 text-black block w-full shadow-md border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500 sm:text-sm  leading-tight focus:outline-none focus:shadow-outline"
                value={formData.firstname}
                onChange={(e) =>
                  setFormData({ ...formData, firstname: e.target.value })
                }
              />
              {errors.firstname && (
                <p className="text-red-500 text-xs italic">
                  {errors.firstname}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="lastname" className="block text-sm font-medium ">
                Last name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="lastname"
                  placeholder="Enter your Last Name"
                  id="lastname"
                  className="py-3 px-4 block text-black w-full shadow-md border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500 sm:text-sm  leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.lastname}
                  onChange={(e) =>
                    setFormData({ ...formData, lastname: e.target.value })
                  }
                />
                {errors.lastname && (
                  <p className="text-red-500 text-xs italic">
                    {errors.lastname}
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="userEmail" className="block text-sm font-medium">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="userEmail"
                  name="userEmail"
                  placeholder="Enter your email"
                  type="email"
                  className="py-3 px-4 block text-black w-full shadow-md border-gray-300 rounded-md leading-tight focus:outline-none focus:shadow-outline focus:ring-purple-500 focus:border-purple-500 sm:text-sm "
                  value={formData.userEmail}
                  onChange={(e) =>
                    setFormData({ ...formData, userEmail: e.target.value })
                  }
                />
                {errors.userEmail && (
                  <p className="text-red-500 text-xs italic">
                    {errors.userEmail}
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium ">
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  placeholder="provide any feedback or message"
                  rows={6}
                  className="py-3 px-4 block text-black w-full shadow-md border-gray-300 rounded-md focus:ring-purple-500 focus:border-purple-500 sm:text-sm leading-tight focus:outline-none focus:shadow-outline"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
                {errors.message && (
                  <p className="text-red-500 text-xs italic">
                    {errors.message}
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2 sm:flex sm:justify-end">
              <button
                type="submit"
                className="mt-3 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset purple-500 focus:ring-offset-purple-200"
              >
                { formData.errors && sending ? (
                 
                <span className="animate-spin">  </span>
                ) : (
                  "Send Message"
                )}
              </button>
            </div>
          

          </form>
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
        </div>
      </div>
    </div>
  );
}

