import {
  AiFillLinkedin,
  AiFillGithub,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import React from "react";
import AnimatedText from "../components/welcome";
import disc from "../assets/img/discord.png";
import usm from "../assets/img/usmael.jpg";

const greeting = "Hi there 👋";
const greeting2 = "Usmael Abdurhaman";
const bio1 = "A frontend developer";
const bio2 = "specialised in React";

const Homepage = () => {
  const Social = [
    {
      id: "gt",
      url: "https://github.com/Usmaelabdureman/",
      icon: <AiFillGithub />,
    },
    {
      id: "tw",
      url: "https://twitter.com/AbduremanUsmael",
      icon: <AiOutlineTwitter />,
    },
    {
      id: "wapp",
      url: "https://wa.me/qr/F2LVDLIE26WHN1",
      icon: <AiOutlineWhatsApp />,
    },
    {
      id: "Discord",
      url: "https://discordapp.com/users/1109067259596509214",
      icon: <img src={disc} width={30} alt="icon" />,
    },
  ];
  const download = () => {
    window.open("https://docs.google.com/document/d/1mS_m5cFaB8AIFtuR6lEiXIFKH2zQHPbQeoCJSt_AoVw/export?format=pdf");
  };

  return (
    <div className="px-5 text-yellow-500 md:px-10 lg:px-20" id="home">
      <div className="flex flex-col items-center justify-center">
        <img
          src={usm}
          alt="Avatar"
          className="w-48 h-48 text-teal-400 rounded-full mt-8"
        />
        <div className="text-center">
          <h1 className="text-orange-400 text-2xl font-bold mt-2">
            {greeting2}
          </h1>
          <h1 className="text-4xl font-bold mb-4 mt-3">
            <AnimatedText />
          </h1>
          <div className="row flex flex-row justify-center text-4xl m-3">
            {Social.map(({ id, icon, url }) => (
              <span className="links">
                <a
                  key={id}
                  href={url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="inline-flex justify-center items-center w-15 h-15 bg-transparent border-2 border-teal-500 rounded-full text-teal-500 transition duration-500 ease-in-out hover:bg-teal-500 hover:text-black hover:shadow-lg m-1"
                >
                  <span className="rounded-full w-10 h-10 flex items-center justify-center">
                    {icon}
                  </span>
                </a>
              </span>
            ))}
          </div>
          <div className="ml-8 mr-8">
            <p className="text-white text-2xl text-justify">
              Highly motivated and experienced{" "}
              <strong>
                <i>Software engineer</i>
              </strong>{" "}
              with a passion for building innovative web applications. Skilled
              in full-stack development with expertise in Python, JavaScript,
              React, Flutter, Node JS, Express, Angular, .NET, MongoDB, AWS,
              MySQL, and PostgreSQL. Able to manage complex projects from
              conception to deployment while working collaboratively with
              cross-functional teams.
            </p>
          </div>
          
          <div class="flex mt-8 justify-center items-center">
            <button className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 text-white font-bold py-2 px-4 rounded-full"
             onClick={download}
            >
              <span>Download CV</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
