import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import firebase from "../config/firebase";
import "firebase/firestore";

const ShimmerEffect = () => {
  return (
    <div className=" px-4 h-40 w-40 rounded-full bg-gray-300 animate-pulse"></div>
  );
};

const SkillCircle = ({ description, percentage, url }) => {
  return (
    <div className=" w-48 h-72  mx-auto md:w-1/2  text-teal-800 font-medium dark:text-teal-400" id='skills'>
        <img className='img-center' src={url} alt ="Skills"  width={100}/>

      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={10}
        styles={buildStyles({
          strokeLinecap: 'round',
          textColor: '#f112f1',
          pathColor: '#60a5fa',
          trailColor: '#d1d5db',
        })}
        
      />
    </div>
  );
};

const SkillSlider = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      // Simulate delay for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      // Fetch images from Firestore
      const db = firebase.firestore();
      const imagesCollection = db.collection("skills");
      imagesCollection.get().then((querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        });
        setImages(docs);
        setLoading(false);
      });
    };

    fetchData();
  }, []);

  const sliderRef = React.useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    gap: 6,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="bg-slate-200 fill-black  py-10  px-5 dark:bg-gray-500 text-white  md:px-20 lg:px-40 ">
      <div className="relative ">
        <h1 className="text-teal-800">Basic Skills__</h1>
        {loading ? (
          <Slider {...settings} ref={sliderRef}>
            <ShimmerEffect />
            <ShimmerEffect />
            <ShimmerEffect />
          </Slider>
        ) : (
          <Slider {...settings} ref={sliderRef}>
            {images.map((skill, indx) => (
              <div key={indx.id}>
                <SkillCircle
                  skill={skill.description}
                  percentage={skill.percentage}
                  url={skill.url}
                />
              </div>
            ))}
          </Slider>
        )}

        <div className="top-0 bottom-0 flex items-center justify-between w-full">
          <span
            className="top-1/2 left-2 transform -translate-y-1/2"
            onClick={handlePrevClick}
          >
            <FaChevronLeft className="text-gray-500 text-3xl" />
          </span>
          <span
            className="absolute top-1/2 right-2 transform -translate-y-1/2"
            onClick={handleNextClick}
          >
            <FaChevronRight className="text-gray-500 text-3xl" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SkillSlider;
