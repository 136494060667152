import React from "react";
import fa from "../assets/img/facebook-logo-2019.svg";
import ins from "../assets/img/instagram.svg";
import tg from "../assets/img/telegram.svg";
import lcd from "../assets/img/leetcode.svg";
import lin from "../assets/img/linkedin.svg";

const socialLinks =[
  {
  id:'instagram',
  icon:ins,
  url:'https://www.instagram.com/esmizth/'
},
  {
  id:'leetcode',
  icon:lcd,
  url:'https://leetcode.com/Usmael/'
},
  {
  id:'telegram',
  icon:tg,
  url:'https://t.me/kenbon4'
},
  {
  id:'linkedin',
  icon:lin,
  url:'https://www.linkedin.com/in/usmael-lkdn'
},
]

const Social = () => {
  return (
    <div className="fixed -left-2 top-1/3">
      <div className="flex flex-col space-y-1 items-center">

        {socialLinks.map((social)=>(
          <button className="animate-button" key={social.id}>
            <a href={social.url} target="_bank">
            <img src={social.icon} width="20px" onMouseOver={social.id} alt="social" />
          </a>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Social;
