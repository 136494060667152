import { useEffect } from 'react';
import {AiOutlineArrowUp} from 'react-icons/ai'
function smoothScroll(target, duration) {
  const targetElement = document.querySelector(target);
  const targetPosition = targetElement.getBoundingClientRect().top;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  let startTime = null;

  function animation(currentTime) {
    if (startTime === null) {
      startTime = currentTime;
    }
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}

function ScrollToTop() {
  useEffect(() => {
    const scrollToTopButton = document.getElementById('scrollToTopButton');
    scrollToTopButton.addEventListener('click', () => {
      smoothScroll('body', 1000);
    });
  }, []);

  return (
    <button
      id="scrollToTopButton"
      className="fixed bottom-5 right-5 z-10 p-3 bg-gray-800 text-white rounded-full shadow-md transition-all duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 focus:outline-none"
    >
    <AiOutlineArrowUp/>
    </button>
  );
}

export default ScrollToTop;
