import React from "react";
import Card from "./card";

const NeonButton = ({ text, onClick }) => {
  return (
    <button
      className="relative inline-block px-6 py-2 text-white font-semibold transition-colors duration-300 ease-in-out border-2 border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={onClick}
    >
      <span className="relative z-10">{text}</span>
      <span className="absolute inset-0 rounded-full bg-gradient-to-r from-indigo-400 to-purple-600 mix-blend-multiply"></span>
      <span className="absolute inset-0 rounded-full bg-gradient-to-r from-indigo-500 to-purple-700 mix-blend-multiply"></span>
    </button>
  );
};

const projects = [
  {
    title: "Rental House Mobile App",
    description:
      "The House Rental Mobile App is a cutting-edge mobile application designed to help users find and rent houses quickly and easily. Developed using the Flutter framework for the frontend and python Django for the backend, the app is optimized for a smooth user experience and fast performance.",
    getImageSrc: () => require("../assets/img/rentHouse.png"),
    url:"https://github.com/Usmaelabdureman/GOJJO-HOUSE-RENT"
  },
  {
    title: "Farm Advisor",
    description:
      "The Farm Advisor Web App is a powerful tool designed to help farmers manage their crops and increase their yields. Built with the React and other frameworks, the app offers a user-friendly interface that allows farmers to easily access and analyze a wide range of data related to their farms.At its core, the Farm Advisor Web App is designed to help farmers make informed decisions about their crops. The app provides real-time weather data, soil analysis, and other key metrics that can help farmers determine the best times to plant, fertilize, and harvest their crops. In addition, the app offers personalized recommendations and alerts based on the specific needs of each farmer and their crops.🔥️",
    getImageSrc: () => require("../assets/img/agino.png"),
    url:"https://github.com/Usmaelabdureman/FarmAdvisor"
  },
  {
    title: "Patient Appointment System",
    description:
      "The Patient Appointment System is a web-based application designed to streamline and manage the appointment booking process between patients and healthcare providers. The system aims to provide a convenient and efficient way for patients to schedule appointments with doctors, specialists, or healthcare facilities.",
    getImageSrc: () => require("../assets/img/web3.png"),
    url:"https://github.com/Usmaelabdureman/Patient-Appointment"
  },
  {
    title: "Delivery App Admin Dashboard",
    description:
      "The delivery app admin dashboard, developed using Next.js and MongoDB, offers a versatile and efficient solution for managing package deliveries. Leveraging Next.js for server-side rendering and MongoDB for data storage, the admin dashboard provides a responsive interface with real-time package tracking, delivery scheduling, and driver management features. With its customizable nature, the dashboard caters to a range of industries requiring package delivery management, ensuring scalability and seamless user experiences.",
    getImageSrc: () => require("../assets/img/web4.png"),
    url:"https://github.com/Usmaelabdureman/delivery-app-admin-dashboard"
  },
  {
    title: "Project Management System",
    description:
      "The full-stack personal project management web app, built with Next.js 13, Prisma, PostgreSQL, and TypeScript, combines powerful technologies to create an efficient and robust application. Leveraging Next.js 13's server-side rendering and static site generation capabilities, the app offers seamless user experiences and optimized performance. Prisma, integrated with PostgreSQL, simplifies database access and management through its ORM features, allowing for efficient data modeling and querying. TypeScript brings static typing and improved code maintainability, catching errors early and enhancing the overall reliability of the application. Together, these technologies enable the development of a scalable, secure, and feature-rich personal project management solution.",
    getImageSrc: () => require("../assets/img/projectm.png"),
    url:"https://github.com/Usmaelabdureman/Project-Management-System"
  },
  {
    title: "Analog Clock With Tk",
    description:
      "The Analog clock  project done with Python and the Turtle library is a classic and simple project that involves creating a clock with hands that move in real-time. The project is ideal for beginners who want to learn more about graphics programming, working with time and dates, and event-driven programming.",
    getImageSrc: () => require("../assets/img/analog.png"),
    url:""
  },
];

const Portfolio = () => {
  const handleClick=()=>{
    console.log("button clicked!")
  }
  return (
    <div className="flex flex-col h-auto justify-start p-8 bg-slate-600 ">
      <h1 className="text-3xl font-bold text-white mb-8 text-center" id="projects">
        Featured Projects

      </h1>
      <div className="flex flex-row  ml-3 justify-center space-x-4 mb-3 ">
        <NeonButton text="Flutter" onClick={handleClick}/>
        <NeonButton text="React" onClick={handleClick}/>
        <NeonButton text="Python" onClick={handleClick}/>
        <NeonButton text="Java" onClick={handleClick}/>

      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-8 ml-5 mr-5">
         {projects.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
            url={project.url}
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
