import Footer from "./components/footer";
import Header from "./components/Header";
import { useState } from "react";
import About from "./pages/About";
import Contact from "./pages/contact";
import "react-toastify/dist/ReactToastify.css";
import Portfolio from "./components/portfolio";
import Social from "./components/social";
import SkillSlider from "./components/Skills";
import ScrollToTop from "./components/scrolltoTop";
import Design from "./components/ParticlesBackground";


export default function App() {
  const [darkMode, setDarkMode] = useState(true);
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };
  return (
    // <div className=" w-full bg-banner-bg bg-no-repeat bg-center bg-cover relative overflow-hidden">
    <div className={`w-full  ${darkMode ? "dark" : ""}`}>
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />

      <div className="top-0 left-0">
        <Design />
      </div>
      <SkillSlider />
      <ScrollToTop />
      <Social />
      <Portfolio />
      <About />
      <Contact />
      <Footer />

    </div>
  );
}
