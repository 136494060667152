import { TypeAnimation } from 'react-type-animation';
const AnimatedText = () => {
    return (
        <>
            <span className='dark:text-orange-600 mobile-font-size' style={{ color: 'rgba(255, 255, 255, 0.8)', textShadow: '2px 2px 4px rgba(0, 1, 0, 0.3)', fontSize: '1em' }} >I am </span>
            <TypeAnimation
                sequence={[
                    'Software Developer', // Types 'Software Developer'
                    3000, // Waits 5s
                    'Frontend Developer', // Types 'Frontend Developer'
                    3000, // Waits 5s
                    'Backend Developer', // Types 'Backend Developer'
                    3000, 
                    'Mobile Developer', // Types 'Mobile Developer'
                    3000, 
                    'Full-Stack Developer', 2000 // Types 'Full-Stack Developer'
                ]}
                wrapper="span"
                cursor={true}
                repeat={Infinity}
                style={{ fontSize: '1em', display: 'inline-block', color: 'rgba(255, 227, 80, 1)', textShadow: '2px 2px 4px rgba(1, 0, 230, 0.3)', }}
            />
        </>
    );
};
export default AnimatedText;