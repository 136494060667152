import { BsFillMoonStarsFill } from "react-icons/bs";
import React, { useState,useRef,useEffect } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import logo from '../assets/img/usmael.png'

const navLinks = [
  { label: "About", url: "#about" ,section:"about" },
  { label: "Projects", url: "#projects" ,section:"projects" },
  { label: "Skills", url: "#skills" ,section:"skills" },
  { label: "Contact", url: "#contact",section:"contact" },
];

const Header = ({ darkMode, toggleDarkMode }) => {

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  const handleClick = (anchor) => {
    return () => {
      const id = `${anchor}-section`;
      const element = document.getElementById(id);
  
      if (element) {
        const offset = element.getBoundingClientRect().top + window.scrollY;
        const start = window.scrollY;
        const gap = offset - start;
        const duration = 0;
        const easeInOutQuad = (t, b, c, d) => {
          t /= d / 2;
          if (t < 1) {
            return c / 2 * t * t + b;
          }
          t--;
          return -c / 2 * (t * (t - 2) - 1) + b;
        };
        const startTime = performance.now();
        const animateScroll = (currentTime) => {
          const timeElapsed = currentTime - startTime;
          const scroll = easeInOutQuad(timeElapsed, start, gap, duration);
          window.scrollTo(0, scroll);
          if (timeElapsed < duration) {
            requestAnimationFrame(animateScroll);
          }
        };
        requestAnimationFrame(animateScroll);
      }
    };
  };
  

  // Handle header show/hide animation depending on the scroll direction
  const headerRef = useRef(null);
 
  useEffect(() => {
    let prevScrollPos = window.scrollY;
    
    const handleScroll = () => {
    const currScrollPos = window.scrollY;
    const currHeaderElement = headerRef.current;
    if (!currHeaderElement) return;
    
    let translateY = currHeaderElement.style.transform.replace(/[^0-9,-]/g, "");
    const speed = 0; 
    if (prevScrollPos > currScrollPos) {
      translateY = Math.min(-200, translateY + speed);      
    } else {
      translateY = Math.max(0, translateY - speed);
    }    
    currHeaderElement.style.transform = `translateY(${translateY}px)`;
    prevScrollPos = currScrollPos;
    };
    
    window.addEventListener("scroll", handleScroll);
    
    return () => {
    window.removeEventListener("scroll", handleScroll);
    };
    }, []);

    
  return (
<div ref={headerRef} 
 style={{translateY:'0',transitionProperty:'transform',transitionDuration:'.s',transitionTimingFunction:'ease-in-out',zIndex:'9999'}} 
  className="fixed top-0 left-0 right-0 z-50 bg-gradient-to-br from-gray-100 to-slate-200 dark:from-slate-500 dark:to-gray-800">
  <div className="container flex items-center justify-between  h-16 mx-auto">
    <div className="flex ml-0">
      <img src={logo} alt="USMAEL" className="w-12 h-12" />
      {/* <h1 className="ml-2 text-xl font-bold text-yellow">{title}</h1> */}
    </div>
    <div className="hidden md:block">
      <ul className="flex items-center space-x-4">
        {navLinks.map((link) => (
          <li key={link.label}>
            <a
              href={link.url}
              onClick={handleClick(link.section)}
              className="accent hover:animate-pulse dark:text-white transition-colors duration-200 hover:text-yellow focus:text-yellow"
            >
              {link.label}
            </a>
          </li>
        ))}
       <a href ="https://sites.google.com/view/usmael-certificates/home" 
       className="text-gray-900 hover:animate-pulse hover:text-teal-400 dark:text-white transition-colors duration-200 hover:text-yellow focus:text-yellow"
        target="_blank" rel="noopener noreferrer dns-prefetch">Certificates</a>
        
       
      </ul>
      
    </div>
    {isSmallScreen ? ( 
<div className="fixed ml-4 bottom-5 right-0">
        <button
          className="text-slate-900 dark:text-white "
          onClick={() => toggleDarkMode(!darkMode)}
        >
          <BsFillMoonStarsFill className="cursor-pointer text-2xl" />
        </button>
      </div>
      ):(
        <div className="flex absolute top-4 right-4">
        <button
          className="text-slate-900 dark:text-white"
          onClick={() => toggleDarkMode(!darkMode)}
        >
          <BsFillMoonStarsFill className="cursor-pointer text-2xl" />
        </button>
      </div>
      )
      }  
    <button
      className="inline-flex items-center justify-center p-2 text-gray-900 rounded-md hover:text-yellow dark:text-gray-100 dark:hover:text-yellow focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow md:hidden"
      onClick={toggleMobileNav}
    >
      <svg
        className={`${isMobileNavOpen ? 'hidden' : 'block'} w-6 h-6`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
      <svg
        className={`${isMobileNavOpen ? 'block' : 'hidden'} w-6 h-6`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
  {isMobileNavOpen && (
    <div className="md:hidden">
      <ul className="bg-gray-100 dark:bg-slate-500 px-2 pt-2 pb-4">
        {navLinks.map((link) => (
          <li key={link.label} className="mb-2">
            <a
              href={link.url}
              onClick={() => {
                toggleMobileNav();
                handleClick(link.section);
              }}
              className="block px-3 py-2 font-medium text-gray-900 rounded-md hover:text-yellow dark:text-gray-100 dark:hover:text-yellow focus:outline-none focus:text-yellow focus:bg-gray-50 dark:focus:bg-gray-700"
            >
              {link.label}
            </a>
          </li>
        ))}
         <a href ="https://sites.google.com/view/usmael-certificates/home" 
       className="text-gray-900 hover:animate-pulse hover:text-teal-400 dark:text-white transition-colors duration-200 hover:text-yellow focus:text-yellow"
        target="_blank" rel="noopener noreferrer">Certificates</a>
      </ul>
    </div>
  )}
</div>
  );
};

export default Header;
