import React from "react";
const About = () => {
  return (
    <div className="bg-gray-100 py-8 px-5 dark:bg-slate-800   md:px-10 lg:px-20" id="about">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold sm:text-4xl text-teal-600  dark:text-teal-400 md:text-6xl">
              About Me
            </h2>
            <div className="mt-6 dark:text-gray-200">
              <p className="text-xl">
                Hi there, my name is Usmael Abdurhaman. I am a highly skilled full-stack software engineer with extensive experience in building industry-standard web applications using MERN and PostgreSQL. Competitive programmer passionate about solving complex problems and creating efficient algorithms. I have a strong background in Prisma and GraphQL, which are cutting-edge technologies used for building scalable and efficient APIs and databases. Additionally, I am well-versed in cloud computing platforms such as Azure and AWS, enabling seamless deployment and management of web applications.
              
              </p>
            
              <p className="mt-4 text-lg">
                As a software engineer, I am passionate about leveraging technology to solve real-world problems and create user-friendly experiences. With my excellent communication and collaboration skills, I thrive in team environments and deliver high-quality software solutions.
              </p>
              <p className="mt-4 text-lg">
                Feel free to check out my{" "}
                <a href="https://github.com/Usmaelabdureman" className="text-teal-300">GitHub</a> and{" "}
                <a href="https://www.linkedin.com/in/usmael/" className="text-teal-300">LinkedIn</a>{" "}
                profiles for more information.
              </p>
            </div>
          </div>
          <div className="mt-8 lg:mt-6">
            <img className="rounded-lg shadow-lg" src='https://i.pravatar.cc/500?img=12' alt="Profile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
