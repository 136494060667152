import { FaArrowRight, FaDev, FaGithub } from 'react-icons/fa';

const Card = ({ title, description, imageSrc,url }) => {
  return (
    <div className="w-full bg-white text-black rounded-lg overflow-hidden  shadow-md hover:shadow-lg transition-all duration-200 focus-within:outline-none">
      <img src={imageSrc} alt={title}  className='transition-transform duration-500 transform hover:scale-110'/>
      <div className="p-4">
        <h3 className="text-lg font-medium ">{title}</h3>
        <p className="text-sm mt-2">{description}</p>
      </div>
      <div className='flex justify-between flex-row '>
      <div className="px-4 pb-4 flex justify-between items-center">
        <p className="text-sm mr-2">See Details</p>
        <FaArrowRight/> 
      </div>
      <div className="px-4 pb-4 flex justify-between items-center">
        <a href={url} target='_blank' className="text-sm mr-2 hover:cursor-pointer">Code</a>
        <FaGithub/> 
      </div>
      </div>
      
    </div>
  );
};

export default Card;

